import states from "@/assets/js/states";
import countries from "@/assets/js/countries";

const lettersAndSpacesValidator = {
    "type": "regex",
    "text": "Invalid characters. Valid characters are A-Z and spaces.",
    "regex": "^[a-zA-Z\\s]+$"
}

const zipCodeValidator = {
    "type": "regex",
    "text": "Invalid characters. Valid characters are 1-9 and '-'.",
    "regex": "^[a-zA-Z0-9\\-]+$"
}

const phoneValidator = {
    "type": "regex",
    "text": "Invalid format. Please use format xxx-xxx-xxxx or +xxxxxxxxxx.",
    "regex": "^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$"
}

const surveyJson = {
    // "title": "Delaware Annual Report",
    "description": "EntityName",
    // "logoPosition": "right",
    "completedHtml": "<h3>Thank you.</h3><h5><a href='<HOME_ANCHOR>'>Please click here to return to the company selection screen.</a></h5>",
    "pages": [
        {
            "name": "page1",
            "title": "Principal Place of Business",
            "elements": [
                {
                    "type": "paneldynamic",
                    "name": "principalAddress",
                    "titleLocation": "hidden",
                    "panelCount": 1,
                    "minPanelCount": 1,
                    "maxPanelCount": 1,
                    "templateElements": [
                        {
                            "type": "text",
                            "name": "addressLine1",
                            "title": "Street Address",
                            "titleLocation": "top",
                            "isRequired": true,
                            "validators": [
                                {
                                    "type": "regex",
                                    "text": "Please enter the company address, not the registered agent address.",
                                    "regex": "^(?!3411 silverside)(?!3411 Silverside).*"
                                }
                            ]
                        },
                        {
                            "type": "text",
                            "name": "addressLine2",
                            "titleLocation": "hidden",
                            "description": "Suite 200",
                            "placeholder": "Apt #, Suite #, etc"
                        },
                        {
                            "type": "text",
                            "name": "city",
                            "title": "City",
                            "titleLocation": "top",
                            "isRequired": true,
                            "validators": [ lettersAndSpacesValidator ]
                        },
                        {
                            "type": "dropdown",
                            "name": "state",
                            "startWithNewLine": true,
                            "title": "State",
                            "titleLocation": "top",
                            "choices": states,
                            "choicesOrder": "asc"
                        },
                        {
                            "type": "text",
                            "name": "zip",
                            "title": "ZIP code",
                            "titleLocation": "top",
                            "isRequired": true,
                            "validators": [ zipCodeValidator ]
                        },
                        {
                            "type": "dropdown",
                            "name": "country",
                            "startWithNewLine": false,
                            "title": "Country",
                            "titleLocation": "top",
                            "choices": countries,
                            "defaultValue": "United States",
                            "isRequired": true
                        },
                        {
                            "type": "text",
                            "name": "phone",
                            "title": "Phone",
                            "titleLocation": "top",
                            "inputType": "tel",
                            "isRequired": true,
                            "validators": [ phoneValidator ]
                        }
                    ],
                }
            ],
        },
        {
            "name": "page2",
            "elements": [
                {
                    "type": "paneldynamic",
                    "name": "officers",
                    "titleLocation": "hidden",
                    "panelCount": 0,
                    "minPanelCount": 0,
                    "maxPanelCount": 4,
                    "templateElements": [
                        {
                            "type": "multipletext",
                            "name": "name",
                            "title": "Officer Name",
                            "titleLocation": "top",
                            "isRequired": true,
                            "items": [
                                {
                                    "name": "firstName",
                                    "title": "First",
                                    "isRequired": true,
                                    "validators": [ lettersAndSpacesValidator ]
                                },
                                {
                                    "name": "lastName",
                                    "title": "Last",
                                    "isRequired": true,
                                    "validators": [ lettersAndSpacesValidator ]
                                }
                            ],
                            "colCount": 2
                        },
                        {
                            "type": "text",
                            "name": "title",
                            "title": "Title",
                            "titleLocation": "top"
                        },
                        {
                            "type": "checkbox",
                            "name": "useCompanyAddress",
                            "titleLocation": "hidden",
                            "choices": [
                                {
                                    "value": "true",
                                    "text": "Use Company Address"
                                }
                            ]
                        },
                        {
                            "type": "paneldynamic",
                            "name": "address",
                            "visibleIf": "{panel.useCompanyAddress} <> ['true']",
                            "title": "Officer Address",
                            "titleLocation": "hidden",
                            "isRequired": true,
                            "templateElements": [
                                {
                                    "type": "text",
                                    "name": "addressLine1",
                                    "title": "Street Address",
                                    "titleLocation": "top",
                                    "isRequired": true
                                },
                                {
                                    "type": "text",
                                    "name": "addressLine2",
                                    "titleLocation": "hidden",
                                    "description": "Suite 200",
                                    "placeholder": "Apt #, Suite #, etc"
                                },
                                {
                                    "type": "text",
                                    "name": "city",
                                    "title": "City",
                                    "titleLocation": "top",
                                    "isRequired": true,
                                    "validators": [ lettersAndSpacesValidator ]
                                },
                                {
                                    "type": "dropdown",
                                    "name": "state",
                                    "startWithNewLine": true,
                                    "title": "State",
                                    "titleLocation": "top",
                                    "choices": states,
                                    "choicesOrder": "asc"
                                },
                                {
                                    "type": "text",
                                    "name": "zip",
                                    "title": "ZIP code",
                                    "titleLocation": "top",
                                    "isRequired": true,
                                    "validators": [ zipCodeValidator ]
                                },
                                {
                                    "type": "dropdown",
                                    "name": "country",
                                    "startWithNewLine": false,
                                    "title": "Country",
                                    "titleLocation": "top",
                                    "choices": countries,
                                    "isRequired": true,
                                    "defaultValue": "United States"
                                }
                            ],
                            "panelCount": 1,
                            "minPanelCount": 1,
                            "maxPanelCount": 4
                        }
                    ]
                },

            ],
            "title": "Officer Information"
        },
        {
            "name": "page3",
            "elements": [
                {
                    "type": "paneldynamic",
                    "name": "directors",
                    // "titleLocation": "hidden",
                    "title": "We will use the primary Director’s name and address as the signatory on the annual report.",
                    "templateElements": [
                        {
                            "type": "multipletext",
                            "name": "name",
                            "title": "Director Name",
                            "titleLocation": "top",
                            "isRequired": true,
                            "items": [
                                {
                                    "name": "firstName",
                                    "title": "First",
                                    "isRequired": true,
                                    "validators": [ lettersAndSpacesValidator ]
                                },
                                {
                                    "name": "lastName",
                                    "title": "Last",
                                    "isRequired": true,
                                    "validators": [ lettersAndSpacesValidator ]
                                }
                            ],
                            "colCount": 2
                        },
                        {
                            "type": "checkbox",
                            "name": "useCompanyAddress",
                            "titleLocation": "hidden",
                            "choices": [
                                {
                                    "value": "true",
                                    "text": "Use Company Address"
                                }
                            ]
                        },
                        {
                            "type": "paneldynamic",
                            "name": "address",
                            "visibleIf": "{panel.useCompanyAddress} <> ['true']",
                            "titleLocation": "hidden",
                            "isRequired": true,
                            "templateElements": [
                                {
                                    "type": "text",
                                    "name": "addressLine1",
                                    "title": "Street Address",
                                    "titleLocation": "top",
                                    "isRequired": true
                                },
                                {
                                    "type": "text",
                                    "name": "addressLine2",
                                    "titleLocation": "hidden",
                                    "description": "Suite 200",
                                    "placeholder": "Apt #, Suite #, etc"
                                },
                                {
                                    "type": "text",
                                    "name": "city",
                                    "title": "City",
                                    "titleLocation": "top",
                                    "isRequired": true,
                                    "validators": [ lettersAndSpacesValidator ]
                                },
                                {
                                    "type": "dropdown",
                                    "name": "state",
                                    "startWithNewLine": true,
                                    "title": "State",
                                    "titleLocation": "top",
                                    "choices": states,
                                    "choicesOrder": "asc"
                                },
                                {
                                    "type": "text",
                                    "name": "zip",
                                    "title": "ZIP code",
                                    "titleLocation": "top",
                                    "isRequired": true,
                                    "validators": [ zipCodeValidator ]
                                },
                                {
                                    "type": "dropdown",
                                    "name": "country",
                                    "startWithNewLine": false,
                                    "title": "Country",
                                    "titleLocation": "top",
                                    "choices": countries,
                                    "isRequired": true,
                                    "defaultValue": "United States"
                                }
                            ],
                            "panelCount": 1,
                            "minPanelCount": 1,
                            "maxPanelCount": 1
                        }
                    ],
                    "panelCount": 1,
                    "minPanelCount": 1,
                    "maxPanelCount": 5
                }
            ],
            "title": "Director Information"
        },
        {
            "name": "page4",
            "title": "Authorization",
            "elements": [
                {
                    "type": "paneldynamic",
                    "name": "authorization",
                    "titleLocation": "hidden",
                    "panelCount": 1,
                    "minPanelCount": 1,
                    "maxPanelCount": 1,
                    "templateElements": [
                        {
                            "type": "text",
                            "name": "authorizedPerson",
                            "title": "Authorized Person",
                            "isRequired": true,
                            "titleLocation": "top",
                            "validators": [ lettersAndSpacesValidator ]
                        },
                        {
                            "type": "signaturepad",
                            "name": "signatureImage",
                            "title": "I authorize Worldwide Incorporators to file the Delaware annual report on my behalf.  I certify the above information to be true and correct.  If revisions to the report are requested, I understand additional fees are required to file an amended report.",
                            "signatureWidth": 700,
                            "titleLocation": "top",
                            "isRequired": true
                        }
                    ],
                    // "description": "Please confirm all of your information and then sign below."
                },
                {
                    "type": "panel",
                    "name": "previewInfo",
                    "elements": [
                        {
                            "type": "html",
                            "name": "previewInstructions",
                            "html": "<h3 class='mt-5'>Click 'preview' to review your answers prior to submitting.</h3>",
                        },
                    ],
                    // "title": "Summary",
                    // "description": "Please confirm all of your information and then sign below."
                }
            ],
        }
    ],
    "showQuestionNumbers": "off",
    "widthMode": "responsive",
    "showPreviewBeforeComplete": "showAnsweredQuestions"
};

function createOfficerDirectorFromSurvey(principalAddress, personFromSurveyData) {
    let contact;
    if(personFromSurveyData.useCompanyAddress){
        contact = JSON.parse(JSON.stringify(principalAddress))
    } else {
        contact = personFromSurveyData.address[0];
    }
    contact.firstName = personFromSurveyData.name.firstName; //name is required
    contact.lastName = personFromSurveyData.name.lastName; //name is required
    contact.title = personFromSurveyData.title;
    return contact
}

function createOfficerDirectorFromPayload(o) {
    return {
        name: {
            firstName: o.firstName,
            lastName: o.lastName
        },
        title: o.title,
        address: [{
            country: o.country,
            addressLine1: o.addressLine1,
            addressLine2: o.addressLine2,
            city: o.city,
            state: o.state,
            zip: o.zip,
        }]
    }
}

const surveyToPayloadMapper = function(s) {
    //s is survey json
    //r is output

    let r = {}

    console.log(s);

    r.principalAddress = s.principalAddress[0]

    if (s.officers) {
        r.officers = []
        for (let i = 0; i < s.officers.length; i++) {
            const contact = createOfficerDirectorFromSurvey(r.principalAddress, s.officers[i]);
            r.officers.push(contact)
        }
    }

    if (s.directors) {
        r.directors = []
        for (let i = 0; i < s.directors.length; i++) {
            const contact = createOfficerDirectorFromSurvey(r.principalAddress, s.directors[i]);
            r.directors.push(contact)
        }
    }

    r.authorizedPerson = s.authorization[0].authorizedPerson;
    r.signatureImage = s.authorization[0].signatureImage;

    return r;
}

const payloadToSurveyMapper = function(p) {
    //Starts with the data object and creates survey data fields
    console.log(p);
    let r = {}
    if (p.principalAddress) {
        r.principalAddress = [p.principalAddress]
    }

    if (p.officers && p.officers.length > 0) {
        r.officers = []
        for (let i = 0; i < p.officers.length; i++) {
            r.officers.push(createOfficerDirectorFromPayload(p.officers[i]))
        }
    }

    if (p.directors && p.directors.length > 0) {
        r.directors = []
        for (let i = 0; i < p.directors.length; i++) {
            r.directors.push(createOfficerDirectorFromPayload(p.directors[i]))
        }
    }

    if (p.authorizedPerson) {
        r.authorization = [{authorizedPerson: p.authorizedPerson}]
    }

    console.log("NEXT")
    console.log(r);
    return r
}

export {surveyJson, surveyToPayloadMapper, payloadToSurveyMapper}
